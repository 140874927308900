/**
 * Componente de Calendário
 * 
 * Este componente exibe um calendário interativo que mostra as datas disponíveis
 * e permite a seleção de datas para reserva.
 */

import React, { useState, useEffect } from 'react';
import { fetchAvailableDates } from '../services/availabilityService';

const Calendar = ({ onDateSelect, month = new Date(), selectedDates = [] }) => {
  const [currentMonth, setCurrentMonth] = useState(month);
  const [availableDates, setAvailableDates] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Função para obter as datas disponíveis do Firebase
  useEffect(() => {
    const getAvailableDates = async () => {
      try {
        setLoading(true);
        setError(null);
        const dates = await fetchAvailableDates();
        setAvailableDates(dates);
      } catch (err) {
        console.error("Erro ao buscar disponibilidade:", err);
        setError("Não foi possível carregar a disponibilidade.");
      } finally {
        setLoading(false);
      }
    };
    
    getAvailableDates();
  }, []);

  // Avançar para o próximo mês
  const nextMonth = () => {
    setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1));
  };

  // Voltar para o mês anterior
  const prevMonth = () => {
    setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 1));
  };

  // Verificar se uma data está disponível
  const isAvailable = (date) => {
    const dateString = date.toISOString().split('T')[0];
    return availableDates[dateString] === true;
  };

  // Verificar se uma data está selecionada
  const isSelected = (date) => {
    const dateString = date.toISOString().split('T')[0];
    return selectedDates.includes(dateString);
  };

  // Manipulador de clique em uma data
  const handleDateClick = (date) => {
    if (!isAvailable(date)) return;
    
    const dateString = date.toISOString().split('T')[0];
    if (onDateSelect) {
      onDateSelect(dateString);
    }
  };

  // Obter dias do mês atual
  const getDaysInMonth = () => {
    const year = currentMonth.getFullYear();
    const month = currentMonth.getMonth();
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    
    const days = [];
    
    // Preencher espaços vazios para o primeiro dia do mês
    const firstDayOfWeek = firstDay.getDay();
    for (let i = 0; i < firstDayOfWeek; i++) {
      days.push(null);
    }
    
    // Preencher os dias do mês
    for (let day = 1; day <= lastDay.getDate(); day++) {
      days.push(new Date(year, month, day));
    }
    
    return days;
  };

  // Formatar nome do mês
  const formatMonth = () => {
    const months = [
      'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
      'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
    ];
    return `${months[currentMonth.getMonth()]} ${currentMonth.getFullYear()}`;
  };

  const days = getDaysInMonth();

  return (
    <div className="calendar">
      <div className="calendar-header">
        <button onClick={prevMonth} className="month-nav">&lt;</button>
        <h3>{formatMonth()}</h3>
        <button onClick={nextMonth} className="month-nav">&gt;</button>
      </div>
      
      {loading && (
        <div className="calendar-loading">Carregando disponibilidade...</div>
      )}
      
      {error && (
        <div className="calendar-error">{error}</div>
      )}
      
      <div className="calendar-days-header">
        <div className="weekday">Dom</div>
        <div className="weekday">Seg</div>
        <div className="weekday">Ter</div>
        <div className="weekday">Qua</div>
        <div className="weekday">Qui</div>
        <div className="weekday">Sex</div>
        <div className="weekday">Sáb</div>
      </div>
      
      <div className="calendar-days">
        {days.map((day, index) => (
          <div 
            key={`day-${index}`} 
            className={`calendar-day ${day ? 'day' : 'empty'} ${
              day && isAvailable(day) ? 'available' : ''
            } ${day && isSelected(day) ? 'selected' : ''}`}
            onClick={() => day && handleDateClick(day)}
          >
            {day ? day.getDate() : ''}
          </div>
        ))}
      </div>
      
      <div className="calendar-legend">
        <div className="legend-item">
          <div className="legend-color available"></div>
          <span>Disponível</span>
        </div>
        <div className="legend-item">
          <div className="legend-color selected"></div>
          <span>Selecionado</span>
        </div>
        <div className="legend-item">
          <div className="legend-color"></div>
          <span>Indisponível</span>
        </div>
      </div>
    </div>
  );
};

export default Calendar; 