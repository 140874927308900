/**
 * Página de Login - Aplicação Web
 * 
 * Esta página permite ao usuário fazer login via e-mail sem senha,
 * conforme especificado no backlog do projeto.
 */

import React, { useState, useContext, useEffect } from 'react';
import AuthContext from '../auth/AuthContext';

const LoginPage = () => {
  // Estado para o campo de e-mail
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  
  // Obter contexto de autenticação
  const { login, isLoading, error, clearError } = useContext(AuthContext);
  
  // Efeito para mostrar erros
  useEffect(() => {
    if (error) {
      alert(error);
      clearError();
    }
  }, [error, clearError]);
  
  // Validação de e-mail simples
  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };
  
  // Manipulador para o botão de login
  const handleLogin = async (e) => {
    e.preventDefault();
    
    if (!isValidEmail(email)) {
      alert('Por favor, informe um e-mail válido');
      return;
    }
    
    const result = await login(email);
    
    if (result.success) {
      setEmailSent(true);
    }
  };
  
  // Renderização condicional baseada no estado de envio do e-mail
  if (emailSent) {
    return (
      <div className="login-container">
        <div className="login-card confirmation-card">
          <div className="logo-container">
            <img 
              src="/images/logo-trailer.jpg" 
              alt="Miguapa Trailer" 
              className="logo" 
            />
          </div>
          
          <h2>E-mail Enviado!</h2>
          
          <p className="confirmation-message">
            Enviamos um link de acesso para <strong>{email}</strong>.<br />
            Por favor, verifique sua caixa de entrada e clique no link para entrar.
          </p>
          
          <button 
            className="button secondary-button"
            onClick={() => setEmailSent(false)}
          >
            Usar outro e-mail
          </button>
        </div>
      </div>
    );
  }
  
  return (
    <div className="login-container">
      <div className="login-card">
        <div className="logo-container">
          <img 
            src="/images/logo-trailer.jpg" 
            alt="Miguapa Trailer" 
            className="logo" 
          />
        </div>
        
        <h2>Bem-vindo ao Miguapa</h2>
        <h3>Reserva de Trailer</h3>
        
        <form onSubmit={handleLogin} className="login-form">
          <div className="form-group">
            <label htmlFor="email">E-mail</label>
            <input
              type="email"
              id="email"
              placeholder="Digite seu e-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          
          <button 
            type="submit" 
            className="button primary-button"
            disabled={isLoading}
          >
            {isLoading ? 'Enviando...' : 'Enviar link de acesso'}
          </button>
        </form>
        
        <p className="info-text">
          Você receberá um e-mail com um link para acessar sua conta.
          Não é necessário criar uma senha!
        </p>
      </div>
    </div>
  );
};

// Estilos para a página
const styles = `
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
}

.login-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.confirmation-card {
  padding: 40px 30px;
}

.logo-container {
  margin-bottom: 20px;
}

.logo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

h2 {
  margin: 0 0 8px;
  color: #333;
  font-size: 24px;
}

h3 {
  margin: 0 0 30px;
  color: #666;
  font-size: 18px;
  font-weight: normal;
}

.login-form {
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
  text-align: left;
}

label {
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
  color: #333;
}

input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
  box-sizing: border-box;
}

.button {
  width: 100%;
  padding: 14px;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.primary-button {
  background-color: #0066CC;
  color: white;
}

.primary-button:hover {
  background-color: #0055b3;
}

.secondary-button {
  background-color: #e0e0e0;
  color: #333;
}

.secondary-button:hover {
  background-color: #d0d0d0;
}

.button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.info-text {
  color: #666;
  font-size: 14px;
  line-height: 1.5;
  margin-top: 20px;
}

.confirmation-message {
  margin: 20px 0;
  font-size: 16px;
  line-height: 1.6;
  color: #333;
}

@media (max-width: 480px) {
  .login-card {
    padding: 20px;
  }
  
  .confirmation-card {
    padding: 30px 20px;
  }
}
`;

// Injetar estilos na página
const StyleInjector = () => {
  useEffect(() => {
    const styleElement = document.createElement('style');
    styleElement.textContent = styles;
    document.head.appendChild(styleElement);
    
    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);
  
  return null;
};

// Exportar componente com estilos injetados
const LoginPageWithStyles = () => (
  <>
    <StyleInjector />
    <LoginPage />
  </>
);

export default LoginPageWithStyles; 