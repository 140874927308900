/**
 * Página Inicial - Web
 * 
 * Esta é a página inicial da aplicação web da Fazenda Miguapa,
 * exibindo informações sobre o local e acesso rápido ao calendário de reservas.
 */

import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Navigation from '../components/Navigation';
import Calendar from '../components/Calendar';
import '../components/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faCalendarAlt, faCalendarCheck, faEnvelope } from '@fortawesome/free-solid-svg-icons';

const HomePage = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [isVisible, setIsVisible] = useState({});
  
  // Função para formatar data no padrão brasileiro
  const formatDateToBR = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return `${day}/${month}/${year}`;
  };
  
  // Refs para os elementos que terão animações
  const heroRef = useRef(null);
  const featureRef = useRef(null);
  const trailerInfoRef = useRef(null);
  const howToReserveRef = useRef(null);
  const galleryRef = useRef(null);
  
  // Manipular seleção de data
  const handleDateSelect = (date) => {
    setSelectedDate(date);
  };
  
  // Observar elementos para animar ao scrollar
  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };
    
    const handleIntersect = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setIsVisible(prev => ({ ...prev, [entry.target.id]: true }));
        }
      });
    };
    
    const observer = new IntersectionObserver(handleIntersect, observerOptions);
    
    // Observar os elementos
    const elements = [
      { ref: heroRef, id: 'hero' },
      { ref: featureRef, id: 'feature' },
      { ref: trailerInfoRef, id: 'trailer-info' },
      { ref: howToReserveRef, id: 'how-to-reserve' },
      { ref: galleryRef, id: 'gallery' }
    ];
    
    elements.forEach(({ ref, id }) => {
      if (ref.current) {
        ref.current.id = id;
        observer.observe(ref.current);
      }
    });
    
    return () => {
      elements.forEach(({ ref }) => {
        if (ref.current) observer.unobserve(ref.current);
      });
    };
  }, []);
  
  return (
    <div className="home-page">
      <Navigation />
      
      <div 
        ref={heroRef} 
        className={`hero-section parallax-container ${isVisible.hero ? 'animate-fade-in' : 'opacity-0'}`}
      >
        <div className="parallax-overlay"></div>
        <div className="hero-content">
          <h1>Fazenda Miguapa</h1>
          <h2>Sistema de Reservas de Trailer</h2>
          <p className="hero-description">
            Reserve o trailer perfeito para sua viagem na Fazenda Miguapa. 
            Conforto e experiência inesquecível garantidos.
          </p>
          <Link to="/reservation" className="button primary-button hero-button">
            Fazer Reserva
          </Link>
        </div>
      </div>
      
      <div className="page-container">
        <section 
          ref={featureRef}
          className={`feature-section ${isVisible.feature ? 'animate-fade-in-up' : 'opacity-0'}`}
        >
          <h2 className="feature-title">Confira as datas disponíveis</h2>
          <p className="feature-description">
            Selecione uma data no calendário abaixo para verificar a disponibilidade e prosseguir com sua reserva.
            As datas em verde estão disponíveis para reserva.
          </p>
          
          <Calendar 
            onDateSelect={handleDateSelect} 
            selectedDates={selectedDate ? [selectedDate] : []}
          />
          
          {selectedDate && (
            <div className="selected-date-actions">
              <p>Você selecionou: {formatDateToBR(selectedDate)}</p>
              <Link 
                to={`/reservation?date=${selectedDate}`} 
                className="button primary-button"
              >
                Prosseguir com a Reserva
              </Link>
            </div>
          )}
        </section>
        
        <section 
          ref={trailerInfoRef}
          className={`trailer-info ${isVisible['trailer-info'] ? 'animate-fade-in-right' : 'opacity-0'}`}
        >
          <h2 className="page-title">Sobre a Fazenda Miguapa</h2>
          
          <div className="two-column-layout">
            <div className="info-column">
              <div className="image-container main-trailer-image">
                <img 
                  src="/images/logo-trailer.jpg" 
                  alt="Fazenda Miguapa" 
                  className="trailer-image" 
                />
              </div>
            </div>
            
            <div className="info-column">
              <h3>O trailer perfeito para sua experiência</h3>
              <p>
                A Fazenda Miguapa oferece todo o conforto e praticidade para que sua estadia seja inesquecível. 
                Um lugar tranquilo e cercado de natureza.
              </p>
              
              <h4>Características</h4>
              <ul className="features-list">
                <li>Acomoda até 4 pessoas confortavelmente</li>
                <li>Cozinha equipada com utensílios essenciais</li>
                <li>Ar condicionado</li>
                <li>Banheiro completo</li>
                <li>Área externa para relaxar</li>
                <li>Estacionamento exclusivo</li>
              </ul>
              
              <h4>Localização</h4>
              <p>
                A Fazenda Miguapa está localizada em uma região privilegiada, 
                com fácil acesso e próxima a diversas atrações naturais.
              </p>
            </div>
          </div>
        </section>
        
        <section 
          ref={galleryRef}
          className={`photo-gallery ${isVisible.gallery ? 'animate-fade-in-up' : 'opacity-0'}`}
        >
          <h2 className="page-title">Galeria de Fotos</h2>
          <p className="gallery-description">
            Conheça um pouco mais da Fazenda Miguapa através das nossas fotos.
          </p>
          
          <div className="gallery-container">
            <div className="gallery-item">
              <img src="/images/logo-trailer.jpg" alt="Fazenda Miguapa - Vista 1" />
            </div>
            <div className="gallery-item">
              <img src="/images/logo-trailer.jpg" alt="Fazenda Miguapa - Vista 2" />
            </div>
            <div className="gallery-item">
              <img src="/images/logo-trailer.jpg" alt="Fazenda Miguapa - Vista 3" />
            </div>
            <div className="gallery-item">
              <img src="/images/logo-trailer.jpg" alt="Fazenda Miguapa - Vista 4" />
            </div>
            <div className="gallery-item">
              <img src="/images/logo-trailer.jpg" alt="Fazenda Miguapa - Vista 5" />
            </div>
            <div className="gallery-item">
              <img src="/images/logo-trailer.jpg" alt="Fazenda Miguapa - Vista 6" />
            </div>
          </div>
        </section>
        
        <section 
          ref={howToReserveRef}
          className={`how-to-reserve ${isVisible['how-to-reserve'] ? 'animate-fade-in-left' : 'opacity-0'}`}
        >
          <h2 className="page-title">Como Reservar</h2>
          
          <div className="steps-container">
            <div className="step-item">
              <div className="step-number">1</div>
              <h3>Escolha as Datas</h3>
              <p>Verifique a disponibilidade no calendário e selecione as datas desejadas para sua estadia.</p>
            </div>
            
            <div className="step-item">
              <div className="step-number">2</div>
              <h3>Preencha o Formulário</h3>
              <p>Forneça suas informações pessoais e detalhes da reserva no formulário de solicitação.</p>
            </div>
            
            <div className="step-item">
              <div className="step-number">3</div>
              <h3>Confirmação</h3>
              <p>Após a análise, você receberá um e-mail de confirmação com instruções para pagamento.</p>
            </div>
            
            <div className="step-item">
              <div className="step-number">4</div>
              <h3>Aproveite sua Estadia</h3>
              <p>Chegue no horário combinado e aproveite dias incríveis na Fazenda Miguapa!</p>
            </div>
          </div>
          
          <div className="cta-container">
            <Link to="/reservation" className="button primary-button">
              Fazer Reserva Agora
            </Link>
          </div>
        </section>
      </div>
      
      <footer className="main-footer">
        <div className="footer-container">
          <div className="footer-info">
            <h3>Fazenda Miguapa</h3>
            <p>Sistema de Reservas de Trailer</p>
            <p><FontAwesomeIcon icon={faEnvelope} className="footer-icon" /> contato@fazendamiguapa.com.br</p>
          </div>
          
          <div className="footer-links">
            <Link to="/"><FontAwesomeIcon icon={faHome} className="footer-icon" /> Página inicial</Link>
            <Link to="/calendar"><FontAwesomeIcon icon={faCalendarAlt} className="footer-icon" /> Calendário</Link>
            <Link to="/reservation"><FontAwesomeIcon icon={faCalendarCheck} className="footer-icon" /> Reservar</Link>
          </div>
        </div>
        
        <div className="copyright">
          &copy; {new Date().getFullYear()} Fazenda Miguapa. Todos os direitos reservados.
        </div>
      </footer>
    </div>
  );
};

// Estilos específicos para a HomePage
const styles = `
.hero-section {
  position: relative;
  color: white;
  padding: 80px 20px;
  text-align: center;
  overflow: hidden;
}

.parallax-container {
  background-image: url('/images/logo-trailer.jpg');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.parallax-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(33, 33, 33, 0.7);
  z-index: 1;
}

.hero-content {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  z-index: 2;
}

.hero-content h1 {
  font-size: 42px;
  margin-bottom: 10px;
  font-weight: 700;
}

.hero-content h2 {
  font-size: 24px;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 20px;
  opacity: 0.9;
}

.hero-description {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 30px;
}

.hero-button {
  font-size: 18px;
  padding: 14px 28px;
  background-color: white;
  color: #3D8361;
}

.hero-button:hover {
  background-color: #f5f5f5;
}

.selected-date-actions {
  text-align: center;
  margin-top: 30px;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.trailer-info {
  padding: 60px 0;
}

.main-trailer-image {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.trailer-image {
  width: 100%;
  height: auto;
  display: block;
}

.info-column h3 {
  margin-top: 0;
  color: #333;
  font-size: 24px;
  margin-bottom: 20px;
}

.info-column h4 {
  color: #3D8361;
  font-size: 18px;
  margin-top: 25px;
  margin-bottom: 15px;
}

.info-column p {
  color: #555;
  line-height: 1.6;
  margin-bottom: 20px;
}

.features-list {
  margin: 0;
  padding-left: 20px;
}

.features-list li {
  margin-bottom: 8px;
  color: #555;
}

.how-to-reserve {
  padding-bottom: 60px;
}

.steps-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-bottom: 40px;
}

.step-item {
  background-color: white;
  border-radius: 8px;
  padding: 30px 20px;
  text-align: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.step-number {
  width: 40px;
  height: 40px;
  background-color: #3D8361;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin: 0 auto 15px;
}

.step-item h3 {
  margin-top: 0;
  color: #333;
  font-size: 18px;
  margin-bottom: 10px;
}

.step-item p {
  color: #666;
  font-size: 14px;
  line-height: 1.5;
}

.cta-container {
  text-align: center;
  margin-top: 20px;
}

.main-footer {
  background-color: #333;
  color: white;
  padding: 40px 20px 20px;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.footer-info h3 {
  margin-top: 0;
  font-size: 20px;
  margin-bottom: 10px;
}

.footer-info p {
  margin: 5px 0;
  color: #ccc;
}

.footer-links {
  display: flex;
  flex-direction: column;
}

.footer-links a {
  color: #ccc;
  text-decoration: none;
  margin-bottom: 10px;
  transition: color 0.3s;
}

.footer-links a:hover {
  color: white;
}

.copyright {
  text-align: center;
  border-top: 1px solid #444;
  padding-top: 20px;
  font-size: 14px;
  color: #999;
}

/* Galeria de fotos */
.photo-gallery {
  padding: 60px 0;
  background-color: #f9f9f9;
}

.gallery-description {
  text-align: center;
  max-width: 800px;
  margin: 0 auto 40px;
  color: #555;
}

.gallery-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.gallery-item {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.gallery-item:hover {
  transform: translateY(-5px);
}

.gallery-item img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  display: block;
}

/* Animações */
.opacity-0 {
  opacity: 0;
}

.animate-fade-in {
  animation: fadeIn 1s forwards;
}

.animate-fade-in-up {
  animation: fadeInUp 1s forwards;
}

.animate-fade-in-right {
  animation: fadeInRight 1s forwards;
}

.animate-fade-in-left {
  animation: fadeInLeft 1s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(-30px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(30px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@media (max-width: 992px) {
  .steps-container {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .gallery-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .hero-content h1 {
    font-size: 32px;
  }
  
  .hero-content h2 {
    font-size: 20px;
  }
  
  .footer-container {
    flex-direction: column;
    gap: 20px;
  }
}

@media (max-width: 576px) {
  .steps-container {
    grid-template-columns: 1fr;
  }
  
  .gallery-container {
    grid-template-columns: 1fr;
  }
}

/* Estilos para ícones */
.nav-icon {
  margin-right: 8px;
  font-size: 16px;
}

.footer-icon {
  margin-right: 8px;
  font-size: 14px;
}

.main-navigation .nav-link {
  display: flex;
  align-items: center;
}

.footer-links a {
  display: flex;
  align-items: center;
}
`;

// Componente para injetar os estilos
const StyleInjector = () => {
  React.useEffect(() => {
    const styleElement = document.createElement('style');
    styleElement.textContent = styles;
    document.head.appendChild(styleElement);
    
    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);
  
  return null;
};

// Exportar componente com estilos injetados
const HomePageWithStyles = () => (
  <>
    <StyleInjector />
    <HomePage />
  </>
);

export default HomePageWithStyles; 