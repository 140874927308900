/**
 * Aplicação Principal - Fazenda Miguapa Web
 * 
 * Este é o componente raiz que configura o sistema de roteamento e autenticação.
 */

import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Provedor de autenticação
import AuthProvider from './auth/AuthProvider';
import AuthContext from './auth/AuthContext';

// Páginas
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import CalendarPage from './pages/CalendarPage';
import ReservationPage from './pages/ReservationPage';

// Componente de carregamento
const LoadingScreen = () => (
  <div style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  }}>
    <div style={{
      border: '4px solid #f3f3f3',
      borderTop: '4px solid #3498db',
      borderRadius: '50%',
      width: '50px',
      height: '50px',
      animation: 'spin 2s linear infinite',
    }} />
    <style>{`
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    `}</style>
  </div>
);

// Rotas da aplicação
const AppRoutes = () => {
  const { isLoading } = useContext(AuthContext);
  
  if (isLoading) {
    return <LoadingScreen />;
  }
  
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      
      <Route path="/" element={<HomePage />} />
      
      <Route path="/calendar" element={<CalendarPage />} />
      
      <Route path="/reservation" element={<ReservationPage />} />
      
      {/* Redirecionar qualquer rota desconhecida para a página inicial */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

// Componente principal da aplicação
const App = () => {
  return (
    <AuthProvider>
      <Router>
        <ToastContainer 
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <AppRoutes />
      </Router>
    </AuthProvider>
  );
};

export default App; 