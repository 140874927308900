/**
 * Componente de Formulário de Reserva
 * 
 * Este componente exibe o formulário para solicitar uma reserva
 * após a seleção de datas no calendário.
 */

import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';

const ReservationForm = ({ selectedDates, onSubmit, onCancel, isLoading = false }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    whatsapp: '',
    guests: 1,
    comments: ''
  });

  const [errors, setErrors] = useState({});

  // Estilos específicos para o formulário de reserva
  useEffect(() => {
    const styles = `
      .form-group .error-message {
        color: #d32f2f;
        font-size: 14px;
        margin-top: 8px;
        display: block;
        font-weight: 500;
        padding-left: 5px;
        border-left: 2px solid #d32f2f;
      }

      .form-group input.error,
      .form-group textarea.error,
      .form-group select.error {
        border-color: #d32f2f;
        background-color: #fff2f2;
      }
    `;
    
    const styleElement = document.createElement('style');
    styleElement.textContent = styles;
    document.head.appendChild(styleElement);
    
    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  // Manipulador para mudanças nos campos do formulário
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    
    // Limpar erro do campo quando for alterado
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: null
      });
    }
  };

  // Validar o formulário
  const validateForm = () => {
    const newErrors = {};
    
    // Validação de nome completo (pelo menos nome e sobrenome)
    if (!formData.name.trim()) {
      newErrors.name = 'Nome é obrigatório';
    } else if (!formData.name.trim().includes(' ') || formData.name.trim().split(' ').length < 2) {
      newErrors.name = 'Por favor, informe seu nome completo (nome e sobrenome)';
    }
    
    // Validação de e-mail
    if (!formData.email.trim()) {
      newErrors.email = 'E-mail é obrigatório';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = 'E-mail inválido';
    }
    
    // Validação de WhatsApp
    if (!formData.whatsapp.trim()) {
      newErrors.whatsapp = 'WhatsApp é obrigatório';
    } else if (formData.whatsapp.replace(/[^0-9]/g, '').length < 11) {
      newErrors.whatsapp = 'WhatsApp inválido. Formato esperado: (XX) XXXXX-XXXX';
    }
    
    // Validação de número de hóspedes
    if (formData.guests < 1) {
      newErrors.guests = 'Informe pelo menos 1 hóspede';
    } else if (formData.guests > 4) {
      newErrors.guests = 'O máximo permitido é de 4 hóspedes';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Manipulador para submissão do formulário
  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (validateForm() && !isLoading) {
      onSubmit({
        ...formData,
        dates: selectedDates
      });
    } else {
      // Focar no primeiro campo com erro
      const firstErrorField = Object.keys(errors)[0];
      if (firstErrorField) {
        const errorElement = document.querySelector(`[name="${firstErrorField}"]`);
        if (errorElement) {
          errorElement.focus();
          errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    }
  };

  // Formatar datas selecionadas para exibição
  const formatSelectedDates = () => {
    if (!selectedDates || selectedDates.length === 0) {
      return 'Nenhuma data selecionada';
    }
    
    // Formatar as datas no formato brasileiro
    return selectedDates.map(date => {
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    }).join(', ');
  };

  // Calcular valor total da reserva
  const calculateTotal = () => {
    const dailyRate = 350; // R$ 350,00
    const days = selectedDates.length;
    return dailyRate * days;
  };

  return (
    <div className="reservation-form">
      <h3>Solicitar Reserva</h3>
      
      <div className="selected-dates">
        <strong>Datas selecionadas:</strong> {formatSelectedDates()}
      </div>
      
      <div className="reservation-summary">
        <p>
          <strong>Valor da diária:</strong> R$ 350,00
        </p>
        <p>
          <strong>Quantidade de diárias:</strong> {selectedDates.length}
        </p>
        <p className="total-value">
          <strong>Valor total:</strong> R$ {calculateTotal().toFixed(2).replace('.', ',')}
        </p>
      </div>
      
      <form onSubmit={handleSubmit} noValidate>
        <div className="form-group">
          <label htmlFor="name">Nome completo *</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Digite seu nome completo"
            className={errors.name ? 'error' : ''}
            disabled={isLoading}
            maxLength={100}
            autoComplete="name"
            required
          />
          {errors.name && <span className="error-message">{errors.name}</span>}
          <small className="field-tip">Informe seu nome e sobrenome completos</small>
        </div>
        
        <div className="form-group">
          <label htmlFor="email">E-mail *</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Digite seu e-mail"
            className={errors.email ? 'error' : ''}
            disabled={isLoading}
            maxLength={100}
            autoComplete="email"
            required
          />
          {errors.email && <span className="error-message">{errors.email}</span>}
          <small className="field-tip">Você receberá uma confirmação neste e-mail</small>
        </div>
        
        <div className="form-group">
          <label htmlFor="whatsapp">WhatsApp *</label>
          <InputMask
            mask="(99) 99999-9999"
            type="tel"
            id="whatsapp"
            name="whatsapp"
            value={formData.whatsapp}
            onChange={handleChange}
            placeholder="(00) 00000-0000"
            className={errors.whatsapp ? 'error' : ''}
            disabled={isLoading}
            autoComplete="tel"
            required
          />
          {errors.whatsapp && <span className="error-message">{errors.whatsapp}</span>}
          <small className="field-tip">Entraremos em contato por WhatsApp para confirmar sua reserva</small>
        </div>
        
        <div className="form-group">
          <label htmlFor="guests">Quantidade de hóspedes *</label>
          <input
            type="number"
            id="guests"
            name="guests"
            min="1"
            max="4"
            value={formData.guests}
            onChange={handleChange}
            className={errors.guests ? 'error' : ''}
            disabled={isLoading}
            required
          />
          {errors.guests && <span className="error-message">{errors.guests}</span>}
          <small className="field-tip">Máximo de 4 hóspedes permitido</small>
        </div>
        
        <div className="form-group">
          <label htmlFor="comments">Observações</label>
          <textarea
            id="comments"
            name="comments"
            value={formData.comments}
            onChange={handleChange}
            placeholder="Informações adicionais sobre a sua reserva"
            rows="4"
            disabled={isLoading}
            maxLength={500}
          ></textarea>
          <small className="field-tip">Opcional - Informe requerimentos especiais ou dúvidas sobre sua estadia</small>
        </div>
        
        <div className="form-buttons">
          <button 
            type="button" 
            className="button secondary-button" 
            onClick={onCancel}
            disabled={isLoading}
          >
            Cancelar
          </button>
          <button 
            type="submit" 
            className="button primary-button"
            disabled={isLoading}
          >
            {isLoading ? 'Processando...' : 'Solicitar Reserva'}
          </button>
        </div>
        
        {isLoading && (
          <div className="loading-indicator">
            <div className="loading-spinner"></div>
            <p>Processando sua reserva...</p>
          </div>
        )}
      </form>
    </div>
  );
};

export default ReservationForm; 