/**
 * Componente de Navegação
 * 
 * Este componente fornece a barra de navegação principal da aplicação web.
 */

import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import AuthContext from '../auth/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faCalendarAlt, faCalendarCheck, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

const Navigation = () => {
  const location = useLocation();
  const { isAuthenticated, logout } = useContext(AuthContext);
  
  // Verificar se a rota atual corresponde ao link
  const isActive = (path) => {
    if (path === '/' && location.pathname === '/') {
      return true;
    }
    if (path === '/calendar' && location.pathname === '/calendar') {
      return true;
    }
    return location.pathname === path;
  };
  
  return (
    <nav className="main-navigation">
      <div className="nav-container">
        <div className="logo">
          <Link to="/">
            <img 
              src="/images/logo-trailer.jpg" 
              alt="Fazenda Miguapa" 
              className="nav-logo" 
            />
            <span className="logo-text">Fazenda Miguapa</span>
          </Link>
        </div>
        
        <div className="nav-links">
          <Link to="/" className={`nav-link ${isActive('/') ? 'active' : ''}`}>
            <FontAwesomeIcon icon={faHome} className="nav-icon" /> Página inicial
          </Link>
          <Link to="/calendar" className={`nav-link ${isActive('/calendar') ? 'active' : ''}`}>
            <FontAwesomeIcon icon={faCalendarAlt} className="nav-icon" /> Calendário
          </Link>
          <Link to="/reservation" className={`nav-link ${isActive('/reservation') ? 'active' : ''}`}>
            <FontAwesomeIcon icon={faCalendarCheck} className="nav-icon" /> Reservar
          </Link>
          
          {isAuthenticated && (
            <button 
              className="nav-link logout-button" 
              onClick={logout}
            >
              <FontAwesomeIcon icon={faSignOutAlt} className="nav-icon" /> Sair
            </button>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navigation; 