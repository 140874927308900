/**
 * Contexto de Autenticação para a Aplicação Web
 * 
 * Este contexto fornece o estado de autenticação e métodos relacionados
 * para todas as páginas e componentes da aplicação web.
 */

import { createContext } from 'react';

// Valores padrão do contexto
const defaultContext = {
  // Estado de autenticação
  isAuthenticated: false,
  user: null,
  
  // Métodos de autenticação
  login: async (email) => {},
  completeLogin: async () => {},
  logout: async () => {},
  
  // Estado de carregamento
  isLoading: false,
  
  // Estado de erro
  error: null,
  clearError: () => {}
};

// Cria o contexto
const AuthContext = createContext(defaultContext);

export default AuthContext; 