/**
 * Serviço de Disponibilidade
 * 
 * Este serviço gerencia as disponibilidades das datas no Firebase Firestore.
 */

import { initializeApp } from 'firebase/app';
import { 
  getFirestore, 
  collection, 
  doc, 
  getDoc, 
  getDocs, 
  setDoc, 
  updateDoc,
  query,
  where,
  Timestamp,
  arrayRemove,
  arrayUnion
} from 'firebase/firestore';
import firebaseConfig from '../firebase.config';

// Inicializar Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// Coleção de disponibilidade
const AVAILABILITY_COLLECTION = 'availability';
const DEFAULT_DOC_ID = 'default';

// Função utilitária para formatar datas para exibição
export const formatDateToBR = (dateString) => {
  const [year, month, day] = dateString.split('-');
  return `${day}/${month}/${year}`;
};

/**
 * Busca as datas disponíveis
 * @returns {Promise<Array<string>>} - Um array com as datas disponíveis
 */
export const fetchAvailableDates = async () => {
  try {
    // Buscar o documento de disponibilidade
    const availabilityRef = doc(db, AVAILABILITY_COLLECTION, DEFAULT_DOC_ID);
    const availabilityDoc = await getDoc(availabilityRef);
    
    if (availabilityDoc.exists()) {
      // Se o documento existe, retornar os dados
      const datesArray = availabilityDoc.data().dates || [];
      
      // Converter para o formato esperado pelo componente Calendar
      const availabilityMap = {};
      datesArray.forEach(dateString => {
        availabilityMap[dateString] = true;
      });
      
      return availabilityMap;
    } else {
      console.warn('Documento de disponibilidade não encontrado. Criando documento padrão...');
      
      // Criar datas disponíveis para os próximos 180 dias
      const availableDates = [];
      const today = new Date();
      
      for (let i = 1; i <= 180; i++) {
        const date = new Date(today);
        date.setDate(today.getDate() + i);
        
        // Excluir alguns dias para simular indisponibilidade
        if (date.getDay() === 0 || date.getDay() === 6) { // Se for sábado ou domingo
          if (Math.random() > 0.7) { // 30% de chance de não incluir
            continue;
          }
        }
        
        availableDates.push(date.toISOString().split('T')[0]); // Formato YYYY-MM-DD
      }
      
      // Salvar no Firestore
      try {
        await setDoc(availabilityRef, { 
          dates: availableDates,
          lastUpdated: new Date().toISOString()
        });
        
        // Converter para o formato esperado pelo componente Calendar
        const availabilityMap = {};
        availableDates.forEach(dateString => {
          availabilityMap[dateString] = true;
        });
        
        return availabilityMap;
      } catch (error) {
        console.error('Erro ao criar documento de disponibilidade:', error);
        // Retornar um objeto vazio em caso de erro
        return {};
      }
    }
  } catch (error) {
    console.error('Erro ao buscar datas disponíveis:', error);
    // Retornar um objeto vazio em caso de erro
    return {};
  }
};

/**
 * Atualiza a disponibilidade de uma ou mais datas
 * @param {Array<string>} dates - Array de datas no formato YYYY-MM-DD
 * @param {boolean} isAvailable - Se as datas estão disponíveis ou não
 * @returns {Promise<void>}
 */
export const updateAvailability = async (dates, isAvailable = false) => {
  try {
    const availabilityRef = doc(db, AVAILABILITY_COLLECTION, DEFAULT_DOC_ID);
    const availabilityDoc = await getDoc(availabilityRef);
    
    if (availabilityDoc.exists()) {
      if (isAvailable) {
        // Adicionar datas ao array de disponibilidade
        await updateDoc(availabilityRef, {
          dates: arrayUnion(...dates),
          lastUpdated: new Date().toISOString()
        });
      } else {
        // Remover datas do array de disponibilidade
        await updateDoc(availabilityRef, {
          dates: arrayRemove(...dates),
          lastUpdated: new Date().toISOString()
        });
      }
    } else {
      // Se o documento não existe, criar com as datas iniciais
      const initialDates = isAvailable ? dates : [];
      await setDoc(availabilityRef, { 
        dates: initialDates,
        lastUpdated: new Date().toISOString()
      });
    }
  } catch (error) {
    console.error('Erro ao atualizar disponibilidade:', error);
    throw error;
  }
};

/**
 * Status das reservas
 * @enum {string}
 */
export const ReservationStatus = {
  PENDING_CONFIRMATION: 'pending_confirmation', // Aguardando confirmação (após solicitação inicial)
  CONFIRMED: 'confirmed',                       // Reserva confirmada (após aprovação do admin)
  PAID: 'paid',                                 // Pagamento recebido
  CANCELED: 'canceled',                         // Reserva cancelada
  COMPLETED: 'completed'                        // Estadia concluída
};

/**
 * Cria uma nova reserva e atualiza a disponibilidade
 * @param {Object} reservationData - Dados da reserva
 * @returns {Promise<string>} - ID da reserva criada
 */
export const createReservation = async (reservationData) => {
  try {
    // Verificar se há datas selecionadas
    if (!reservationData.dates || !Array.isArray(reservationData.dates) || reservationData.dates.length === 0) {
      throw new Error('Nenhuma data selecionada para reserva');
    }

    // Verificar dados obrigatórios
    if (!reservationData.name || !reservationData.email || !reservationData.whatsapp) {
      throw new Error('Dados pessoais incompletos');
    }

    // Criar um documento na coleção de reservas
    const reservationsCollection = collection(db, 'reservations');
    const reservationRef = doc(reservationsCollection);
    
    // Formatar datas para exibição no e-mail
    const formattedDates = reservationData.dates.map(date => formatDateToBR(date));
    
    // Calcular valor total
    const dailyRate = 350; // R$ 350,00
    const totalAmount = dailyRate * reservationData.dates.length;
    
    // Preparar dados da reserva
    const reservation = {
      ...reservationData,
      id: reservationRef.id,
      status: ReservationStatus.PENDING_CONFIRMATION, // Status inicial: aguardando confirmação
      statusHistory: [{
        status: ReservationStatus.PENDING_CONFIRMATION,
        timestamp: Timestamp.now(),
        comment: 'Solicitação de reserva enviada pelo cliente'
      }],
      totalAmount,
      formattedDates, // Adiciona as datas formatadas para facilitar exibição
      emailSent: false, // Flag para controlar se o e-mail foi enviado
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now()
    };
    
    // Salvar reserva
    await setDoc(reservationRef, reservation);
    
    // Atualizar disponibilidade das datas
    await updateAvailability(reservationData.dates, false);
    
    try {
      // Enviar e-mail de confirmação para o cliente
      await sendConfirmationEmail(reservation);
    } catch (emailError) {
      console.error('Erro ao enviar e-mail de confirmação:', emailError);
      // Não interromper o fluxo se o e-mail falhar
    }
    
    return reservationRef.id;
  } catch (error) {
    console.error('Erro ao criar reserva:', error);
    // Personalizar mensagens de erro
    if (error.message.includes('data selecionada')) {
      throw new Error('Selecione pelo menos uma data para prosseguir com a reserva.');
    } else if (error.message.includes('incompletos')) {
      throw new Error('Preencha todos os campos obrigatórios do formulário.');
    } else if (error.code === 'permission-denied') {
      throw new Error('Sem permissão para realizar esta operação. Verifique se você está logado.');
    } else {
      throw new Error('Não foi possível processar sua reserva. Tente novamente mais tarde.');
    }
  }
};

/**
 * Envia e-mail de confirmação para o cliente
 * @param {Object} reservation - Dados da reserva
 * @returns {Promise<void>}
 */
const sendConfirmationEmail = async (reservation) => {
  try {
    // Aqui implementaremos o envio do e-mail
    // Neste momento, vamos apenas simular que o e-mail foi enviado
    console.log(`E-mail de confirmação enviado para ${reservation.email}`);
    
    // Atualizar a reserva para indicar que o e-mail foi enviado
    const reservationRef = doc(db, 'reservations', reservation.id);
    await updateDoc(reservationRef, {
      emailSent: true,
      updatedAt: Timestamp.now()
    });
    
    return true;
  } catch (error) {
    console.error('Erro ao enviar e-mail de confirmação:', error);
    return false;
  }
};

export default {
  fetchAvailableDates,
  updateAvailability,
  createReservation,
  ReservationStatus,
  formatDateToBR
}; 