/**
 * Página de Reserva - Web
 * 
 * Esta página permite ao usuário selecionar datas e preencher o formulário
 * para solicitar uma reserva do trailer.
 */

import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Navigation from '../components/Navigation';
import Calendar from '../components/Calendar';
import ReservationForm from '../components/ReservationForm';
import { createReservation } from '../services/availabilityService';
import '../components/styles.css';

const ReservationPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  
  // Estado para armazenar as datas selecionadas
  const [selectedDates, setSelectedDates] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [reservationSuccess, setReservationSuccess] = useState(false);
  const [reservationId, setReservationId] = useState(null);
  const [reservationData, setReservationData] = useState(null);
  const [loading, setLoading] = useState(false);
  
  // Ao carregar, verificar se há datas selecionadas vindas de outra página
  useEffect(() => {
    // Verificar parâmetros na URL
    const searchParams = new URLSearchParams(location.search);
    const dateParam = searchParams.get('date');
    
    if (dateParam) {
      // Se vier uma data via parâmetro, usar e mostrar formulário
      setSelectedDates([dateParam]);
      setShowForm(true);
    } 
    // Verificar location state (compatibilidade com código anterior)
    else if (location.state) {
      if (Array.isArray(location.state.selectedDates)) {
        setSelectedDates(location.state.selectedDates);
        setShowForm(location.state.selectedDates.length > 0);
      } 
      else if (typeof location.state.selectedDate === 'string') {
        setSelectedDates([location.state.selectedDate]);
        setShowForm(true);
      }
    }
  }, [location]);
  
  // Manipulador para seleção de datas no calendário
  const handleDateSelect = (date) => {
    const dateIndex = selectedDates.indexOf(date);
    
    if (dateIndex === -1) {
      // Adicionar a data se não estiver selecionada
      setSelectedDates([...selectedDates, date]);
    } else {
      // Remover a data se já estiver selecionada
      const newDates = [...selectedDates];
      newDates.splice(dateIndex, 1);
      setSelectedDates(newDates);
    }
    
    // Mostrar formulário se houver datas selecionadas
    setShowForm(selectedDates.length > 0 || dateIndex === -1);
  };
  
  // Ordenar datas selecionadas
  const sortedSelectedDates = [...selectedDates].sort();
  
  // Manipulador para voltar ao calendário (esconder formulário)
  const handleCancelForm = () => {
    setShowForm(false);
  };
  
  // Manipulador para o envio do formulário
  const handleFormSubmit = async (formData) => {
    try {
      setLoading(true);
      
      // Preparar dados da reserva
      const reservationData = {
        ...formData,
        dates: sortedSelectedDates,
        totalAmount: sortedSelectedDates.length * 350, // R$ 350,00 por dia
        paymentStatus: 'pending'
      };
      
      // Salvar os dados da reserva para uso na tela de sucesso
      setReservationData(reservationData);
      
      // Criar reserva e atualizar disponibilidade no Firebase
      const newReservationId = await createReservation(reservationData);
      setReservationId(newReservationId);
      
      // Indicar sucesso
      setReservationSuccess(true);
      
      toast.success('Solicitação enviada com sucesso!');
    } catch (err) {
      console.error('Erro ao processar reserva:', err);
      // Mostrar a mensagem de erro específica retornada pelo serviço
      toast.error(err.message || 'Não foi possível processar sua reserva. Por favor, tente novamente mais tarde.');
    } finally {
      setLoading(false);
    }
  };
  
  // Página de sucesso após envio do formulário
  if (reservationSuccess) {
    return (
      <div className="reservation-page">
        <Navigation />
        
        <div className="page-container">
          <div className="success-message">
            <div className="success-icon">✓</div>
            <h1>Solicitação Enviada com Sucesso!</h1>
            <p>
              Sua solicitação de reserva foi recebida e está em análise. 
              Enviamos um e-mail de confirmação para <strong>{reservationData?.email}</strong> com os detalhes da sua reserva.
            </p>
            <p>
              Em breve, nossa equipe entrará em contato pelo WhatsApp para confirmar a disponibilidade 
              e fornecer instruções para pagamento.
            </p>
            {reservationId && (
              <div className="reservation-id">
                <strong>Código da Reserva:</strong> {reservationId}
              </div>
            )}
            <button 
              className="button primary-button"
              onClick={() => navigate('/')}
            >
              Voltar à Página Inicial
            </button>
          </div>
        </div>
      </div>
    );
  }
  
  return (
    <div className="reservation-page">
      <Navigation />
      
      <div className="page-container">
        <h1 className="page-title">Fazer Reserva</h1>
        
        <p className="page-description">
          Selecione as datas desejadas no calendário abaixo e preencha o formulário 
          para solicitar sua reserva na Fazenda Miguapa.
        </p>
        
        <div className="reservation-info">
          <h3>Informações Importantes:</h3>
          <ul>
            <li>O valor da diária é de R$ 350,00.</li>
            <li>É necessário um pagamento de 50% no momento da reserva.</li>
            <li>Cancelamentos devem ser feitos com pelo menos 7 dias de antecedência para reembolso parcial.</li>
            <li>É possível acomodar até 4 pessoas no trailer.</li>
          </ul>
        </div>
        
        {!showForm ? (
          <div className="calendar-container">
            <h2 className="section-title">Selecione as Datas</h2>
            <Calendar 
              onDateSelect={handleDateSelect}
              selectedDates={selectedDates}
            />
            
            {selectedDates.length > 0 && (
              <div className="dates-selected-action">
                <p>
                  <strong>{selectedDates.length} data(s) selecionada(s):</strong> 
                  {sortedSelectedDates.map(date => {
                    const [year, month, day] = date.split('-');
                    return `${day}/${month}/${year}`;
                  }).join(', ')}
                </p>
                <button 
                  className="button primary-button"
                  onClick={() => setShowForm(true)}
                >
                  Continuar para o Formulário
                </button>
              </div>
            )}
          </div>
        ) : (
          <ReservationForm 
            selectedDates={sortedSelectedDates}
            onSubmit={handleFormSubmit}
            onCancel={handleCancelForm}
            isLoading={loading}
          />
        )}
      </div>
    </div>
  );
};

// Estilos específicos para a ReservationPage
const styles = `
.reservation-page {
  min-height: 100vh;
  background-color: #f5f7fa;
}

.section-title {
  font-size: 22px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.calendar-container {
  max-width: 700px;
  margin: 0 auto 40px;
}

.dates-selected-action {
  margin-top: 30px;
  padding: 20px;
  background-color: #EEF2E6;
  border-radius: 8px;
  text-align: center;
}

.dates-selected-action p {
  margin-bottom: 15px;
}

.reservation-info {
  background-color: #EEF2E6;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  border-left: 4px solid #3D8361;
}

.reservation-info h3 {
  color: #3D8361;
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 18px;
}

.reservation-info ul {
  margin: 0;
  padding-left: 20px;
}

.reservation-info li {
  margin-bottom: 10px;
  color: #555;
  line-height: 1.5;
}

.success-message {
  background-color: white;
  border-radius: 10px;
  padding: 40px;
  text-align: center;
  max-width: 600px;
  margin: 60px auto;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.success-icon {
  width: 80px;
  height: 80px;
  background-color: #3D8361;
  color: white;
  font-size: 42px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px;
}

.success-message h1 {
  color: #333;
  font-size: 28px;
  margin-bottom: 20px;
}

.success-message p {
  color: #666;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 30px;
}

.success-message .button {
  padding: 14px 28px;
  font-size: 16px;
}
`;

// Componente para injetar os estilos
const StyleInjector = () => {
  React.useEffect(() => {
    const styleElement = document.createElement('style');
    styleElement.textContent = styles;
    document.head.appendChild(styleElement);
    
    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);
  
  return null;
};

// Exportar componente com estilos injetados
const ReservationPageWithStyles = () => (
  <>
    <StyleInjector />
    <ReservationPage />
  </>
);

export default ReservationPageWithStyles; 