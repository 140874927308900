/**
 * Provedor de Autenticação para a Aplicação Web
 * 
 * Este componente provê o estado e métodos de autenticação para toda a aplicação.
 * Gerencia o estado de autenticação e interage com os serviços do Firebase.
 */

import React, { useState, useEffect } from 'react';
import AuthContext from './AuthContext';
import { sendLoginLink, completeSignIn, checkAuthState, logoutUser } from './firebaseAuth';

export const AuthProvider = ({ children }) => {
  // Estados de autenticação
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // Verificar estado de autenticação no carregamento inicial
  useEffect(() => {
    const checkAuth = async () => {
      try {
        // Verificar autenticação atual
        const authState = await checkAuthState();
        
        setIsAuthenticated(authState.isAuthenticated);
        setUser(authState.user);
      } catch (err) {
        setError('Falha ao verificar autenticação');
        console.error('Erro ao verificar estado de autenticação:', err);
      } finally {
        setIsLoading(false);
      }
    };
    
    checkAuth();
  }, []);
  
  // Verificar se a URL atual contém um link de autenticação
  useEffect(() => {
    const completeAuthFromEmailLink = async () => {
      // Obter a URL atual
      const currentUrl = window.location.href;
      
      // Verificar se é um link de autenticação por e-mail
      if (currentUrl && currentUrl.includes('apiKey=')) {
        setIsLoading(true);
        
        try {
          const result = await completeLogin(currentUrl);
          
          if (!result.success) {
            setError(result.error || 'Falha ao completar autenticação');
          }
        } catch (err) {
          setError(err.message || 'Falha ao processar link de autenticação');
          console.error('Erro ao processar link de autenticação:', err);
        } finally {
          setIsLoading(false);
          
          // Limpar a URL após o processamento
          window.history.replaceState({}, document.title, window.location.pathname);
        }
      }
    };
    
    completeAuthFromEmailLink();
  }, []);
  
  // Método para iniciar login com e-mail
  const login = async (email) => {
    setIsLoading(true);
    setError(null);
    
    try {
      // Salvar e-mail para uso posterior
      localStorage.setItem('emailForSignIn', email);
      
      // Enviar e-mail de login
      const result = await sendLoginLink(email);
      
      if (!result.success) {
        throw new Error(result.error || 'Falha ao enviar link de login');
      }
      
      return result;
    } catch (err) {
      setError(err.message || 'Falha ao iniciar login');
      console.error('Erro no login:', err);
      return { success: false, error: err.message };
    } finally {
      setIsLoading(false);
    }
  };
  
  // Método para completar o login com o link do e-mail
  const completeLogin = async (url) => {
    setIsLoading(true);
    setError(null);
    
    try {
      // Obter e-mail do localStorage
      const email = localStorage.getItem('emailForSignIn');
      
      // Completar processo de login
      const result = await completeSignIn(url, email);
      
      if (!result.success) {
        throw new Error(result.error || 'Falha ao completar login');
      }
      
      // Atualizar estado de autenticação
      setIsAuthenticated(true);
      setUser(result.user);
      
      // Limpar e-mail armazenado
      localStorage.removeItem('emailForSignIn');
      
      return { success: true, user: result.user };
    } catch (err) {
      setError(err.message || 'Falha ao completar login');
      console.error('Erro ao completar login:', err);
      return { success: false, error: err.message };
    } finally {
      setIsLoading(false);
    }
  };
  
  // Método para logout
  const logout = async () => {
    setIsLoading(true);
    setError(null);
    
    try {
      const result = await logoutUser();
      
      if (!result.success) {
        throw new Error(result.error || 'Falha ao fazer logout');
      }
      
      // Limpar estado de autenticação
      setIsAuthenticated(false);
      setUser(null);
      
      return { success: true };
    } catch (err) {
      setError(err.message || 'Falha ao fazer logout');
      console.error('Erro ao fazer logout:', err);
      return { success: false, error: err.message };
    } finally {
      setIsLoading(false);
    }
  };
  
  // Limpar mensagens de erro
  const clearError = () => setError(null);
  
  // Montar o valor do contexto
  const contextValue = {
    isAuthenticated,
    user,
    isLoading,
    error,
    login,
    completeLogin,
    logout,
    clearError
  };
  
  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider; 