/**
 * Firebase Authentication Configuration
 * 
 * Este módulo configura e exporta as funcionalidades de autenticação do Firebase
 * Implementa o login sem senha via link de e-mail conforme especificado no backlog
 */

import { initializeApp } from 'firebase/app';
import { 
  getAuth, 
  sendSignInLinkToEmail, 
  isSignInWithEmailLink, 
  signInWithEmailLink,
  signOut,
  onAuthStateChanged
} from 'firebase/auth';
import firebaseConfig from '../firebase.config';

// Inicializa o Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Configurações do link de autenticação por e-mail
const actionCodeSettings = {
  // URL para onde o usuário será redirecionado após clicar no link
  url: window.location.origin,
  // Manipula o código na aplicação em vez de redirecionamento
  handleCodeInApp: true
};

/**
 * Envia um link de login para o e-mail do usuário
 * 
 * @param {string} email - E-mail do usuário 
 * @returns {Promise<Object>} - Resultado do envio
 */
export const sendLoginLink = async (email) => {
  try {
    // Em ambiente mobile, precisamos armazenar o e-mail para uso posterior
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem('emailForSignIn', email);
    } else {
      // Em React Native, podemos usar AsyncStorage
      // Implementação dependerá da configuração final
      console.log('E-mail armazenado para autenticação:', email);
    }
    
    await sendSignInLinkToEmail(auth, email, actionCodeSettings);
    return { 
      success: true, 
      message: 'Link de login enviado! Verifique seu e-mail.' 
    };
  } catch (error) {
    console.error('Erro ao enviar link de login:', error);
    return { 
      success: false, 
      error: error.message 
    };
  }
};

/**
 * Completa o processo de autenticação com o link do e-mail
 * 
 * @param {string} url - URL contendo o link de autenticação
 * @param {string} [providedEmail] - E-mail fornecido (caso não esteja em localStorage)
 * @returns {Promise<Object>} - Dados do usuário ou erro
 */
export const completeSignIn = async (url, providedEmail = null) => {
  try {
    if (isSignInWithEmailLink(auth, url)) {
      // Obter o e-mail do localStorage ou parâmetro
      let email = providedEmail;
      
      if (!email && typeof localStorage !== 'undefined') {
        email = localStorage.getItem('emailForSignIn');
      }
      
      // Se ainda estiver faltando o e-mail, solicitar ao usuário
      if (!email) {
        email = window.prompt('Por favor, forneça seu e-mail para confirmação');
      }
      
      // Autenticação com o link do e-mail
      const result = await signInWithEmailLink(auth, email, url);
      const user = result.user;
      
      // Limpar e-mail do armazenamento
      if (typeof localStorage !== 'undefined') {
        localStorage.removeItem('emailForSignIn');
      }
      
      return { success: true, user };
    } else {
      return { success: false, error: 'Link de autenticação inválido' };
    }
  } catch (error) {
    console.error('Erro ao autenticar com link:', error);
    return { success: false, error: error.message };
  }
};

/**
 * Verifica o estado atual da autenticação
 * 
 * @returns {Promise<Object>} - Estado da autenticação
 */
export const checkAuthState = () => {
  return new Promise((resolve) => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      unsubscribe();
      if (user) {
        resolve({ isAuthenticated: true, user });
      } else {
        resolve({ isAuthenticated: false, user: null });
      }
    });
  });
};

/**
 * Encerra a sessão do usuário atual
 * 
 * @returns {Promise<Object>} - Resultado do logout
 */
export const logoutUser = async () => {
  try {
    await signOut(auth);
    return { success: true, message: 'Logout realizado com sucesso' };
  } catch (error) {
    console.error('Erro ao fazer logout:', error);
    return { success: false, error: error.message };
  }
};

export default {
  auth,
  sendLoginLink,
  completeSignIn,
  checkAuthState,
  logoutUser
}; 