/**
 * Página de Calendário - Web
 * 
 * Esta página exibe o calendário completo de disponibilidade do trailer,
 * permitindo visualizar várias datas e meses.
 */

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Navigation from '../components/Navigation';
import Calendar from '../components/Calendar';
import '../components/styles.css';

const CalendarPage = () => {
  const [selectedDates, setSelectedDates] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  
  // Adicionar ou remover uma data da seleção
  const handleDateSelect = (date) => {
    const dateIndex = selectedDates.indexOf(date);
    
    if (dateIndex === -1) {
      // Adicionar a data se não estiver selecionada
      setSelectedDates([...selectedDates, date]);
    } else {
      // Remover a data se já estiver selecionada
      const newDates = [...selectedDates];
      newDates.splice(dateIndex, 1);
      setSelectedDates(newDates);
    }
  };
  
  // Avançar para o próximo mês
  const handleNextMonth = () => {
    const nextMonth = new Date(currentMonth);
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    setCurrentMonth(nextMonth);
  };
  
  // Voltar para o mês anterior
  const handlePreviousMonth = () => {
    const prevMonth = new Date(currentMonth);
    prevMonth.setMonth(prevMonth.getMonth() - 1);
    setCurrentMonth(prevMonth);
  };
  
  // Formatar datas para visualização
  const formatDateList = (dates) => {
    return dates.map(date => {
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    }).join(', ');
  };
  
  return (
    <div className="calendar-page">
      <Navigation />
      
      <div className="page-container">
        <h1 className="page-title">Calendário de Disponibilidade</h1>
        
        <p className="page-description">
          Verifique as datas disponíveis para reserva do Miguapa Trailer. As datas em verde estão 
          disponíveis e você pode selecionar múltiplas datas consecutivas para uma estadia mais longa.
        </p>
        
        <div className="calendar-controls">
          <button 
            className="calendar-control-button" 
            onClick={handlePreviousMonth}
          >
            Mês Anterior
          </button>
          <button 
            className="calendar-control-button" 
            onClick={handleNextMonth}
          >
            Próximo Mês
          </button>
        </div>
        
        <div className="month-grid">
          <div className="month-container">
            <Calendar 
              onDateSelect={handleDateSelect}
              selectedDates={selectedDates}
              month={currentMonth}
            />
          </div>
          
          <div className="month-container">
            {(() => {
              const nextMonth = new Date(currentMonth);
              nextMonth.setMonth(nextMonth.getMonth() + 1);
              return (
                <Calendar 
                  onDateSelect={handleDateSelect}
                  selectedDates={selectedDates}
                  month={nextMonth}
                />
              );
            })()}
          </div>
          
          <div className="month-container">
            {(() => {
              const nextMonth = new Date(currentMonth);
              nextMonth.setMonth(nextMonth.getMonth() + 2);
              return (
                <Calendar 
                  onDateSelect={handleDateSelect}
                  selectedDates={selectedDates}
                  month={nextMonth}
                />
              );
            })()}
          </div>
        </div>
        
        {selectedDates.length > 0 && (
          <div className="selected-dates-panel">
            <h3>Datas Selecionadas</h3>
            <p>{formatDateList(selectedDates)}</p>
            <Link 
              to={{
                pathname: "/reservation",
                state: { selectedDates }
              }} 
              className="button primary-button"
            >
              Prosseguir com Reserva
            </Link>
          </div>
        )}
        
        <div className="calendar-legend">
          <div className="legend-item">
            <div className="legend-color available"></div>
            <span>Disponível</span>
          </div>
          <div className="legend-item">
            <div className="legend-color selected"></div>
            <span>Selecionado</span>
          </div>
          <div className="legend-item">
            <div className="legend-color unavailable"></div>
            <span>Indisponível</span>
          </div>
        </div>
        
        <div className="calendar-notes">
          <h3>Informações importantes</h3>
          <ul>
            <li>As reservas devem ser feitas com pelo menos 3 dias de antecedência.</li>
            <li>O check-in é realizado a partir das 14h e o check-out até as 12h.</li>
            <li>Para estadias mais longas, consultar disponibilidade diretamente.</li>
            <li>Datas em branco não estão disponíveis para reserva.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

// Estilos específicos para a CalendarPage
const styles = `
.calendar-page {
  min-height: 100vh;
  background-color: #f5f7fa;
}

.calendar-controls {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 30px;
}

.calendar-control-button {
  background-color: #3D8361;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s;
}

.calendar-control-button:hover {
  background-color: #346A4E;
}

.month-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-bottom: 40px;
}

.month-container {
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.selected-dates-panel {
  background-color: #EEF2E6;
  padding: 20px;
  border-radius: 8px;
  margin: 30px 0;
  text-align: center;
}

.selected-dates-panel h3 {
  margin-top: 0;
  color: #3D8361;
  margin-bottom: 10px;
}

.selected-dates-panel p {
  margin-bottom: 20px;
}

.calendar-legend {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 30px 0;
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.legend-color {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.legend-color.available {
  background-color: #EEF2E6;
  border: 1px solid #D0E7D2;
}

.legend-color.selected {
  background-color: #3D8361;
}

.legend-color.unavailable {
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
}

.calendar-notes {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  margin-top: 30px;
}

.calendar-notes h3 {
  color: #333;
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 18px;
}

.calendar-notes ul {
  margin: 0;
  padding-left: 20px;
}

.calendar-notes li {
  margin-bottom: 10px;
  color: #555;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .month-grid {
    grid-template-columns: 1fr;
  }
}
`;

// Componente para injetar os estilos
const StyleInjector = () => {
  React.useEffect(() => {
    const styleElement = document.createElement('style');
    styleElement.textContent = styles;
    document.head.appendChild(styleElement);
    
    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);
  
  return null;
};

// Exportar componente com estilos injetados
const CalendarPageWithStyles = () => (
  <>
    <StyleInjector />
    <CalendarPage />
  </>
);

export default CalendarPageWithStyles; 