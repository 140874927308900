/**
 * Configuração do Firebase
 * 
 * Este arquivo contém as credenciais e configuração para acesso ao Firebase.
 */

const firebaseConfig = {
  apiKey: "AIzaSyBlSIMXmz9OBodkOKuPSgx2nxA0PqjQPy4",
  authDomain: "miguapa-booking-app.firebaseapp.com",
  projectId: "miguapa-booking-app",
  storageBucket: "miguapa-booking-app.appspot.com",
  messagingSenderId: "480144723831",
  appId: "1:480144723831:web:3b7ab25eca8bd5e1189b72",
  measurementId: "G-8GPDKN0FSM"
};

export default firebaseConfig; 